/* 
font-family: 'Raleway', sans-serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Open Sans', sans-serif; 
*/
body::-webkit-scrollbar {
  display: none;
}
body {
  -ms-overflow-style: none;
}

.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  /* background-color: #DDDFDF; */
}

html,body{
  height:100%;
  background-color: #e9ecef;
  /* overflow: hidden; */
}

/* BootStrap */
.jumboSpacing {
  padding-top: 20px;
  padding-bottom: 0px;
}
/* end BootStrap */

/* NAV */
.title:hover{
  /* transition: 1s; */
  animation-iteration-count: infinite;
  text-decoration: none;
  color: white;
  animation-name: flash;
  animation-duration: 2.5s;
  animation-direction: alternate;
}
.title{
  opacity: 1;
  text-decoration: none;
  font-size: 30px;
  color: white;
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
  margin-right: 40px;
  /* float: left; */
}
.title > h1{
  float: left;
  margin-left: 3%;
  text-shadow: 2px 2px gray;
  margin-bottom: 0%;
}

.projectCardBody {
  height: 275px;
}
.projectCardTitle {
  height: 75px !important;
}

.customNav {
  /* background-color: (#343a40, .25); */
  display: flex;
  justify-content: space-around;
  background-position: 100% 0%;
  position: sticky;
  top: 0;
  z-index: 1020;
  opacity: .8;
  transition-duration: .45s;
  /* position: sticky;
  top: 0;
  z-index: 1020;
  flex-flow: row nowrap;
  justify-content: flex-start;
  display: flex; */
  padding: .5rem 1rem;
  padding-bottom: 1.5%;
  padding-top: 1.3%;
  /* border-bottom: solid 4px #808080; */
  background-color: #343a40;
  box-shadow:5px 0px 5px 5px rgb(148, 148, 148);
}
/* .customNav::after{
  background-image: url('../src/low_contrast_linen.png');
  opacity:.5;
  z-index: -1;
} */

.customNav:hover{
  box-shadow:5px 0px 5px 5px rgb(83, 83, 83);
  opacity: 1;
  
}
.customNav .title {
  width: 30%;
}

.customNavLink {
  color: white;
  font-family: 'Raleway', sans-serif;
  margin-right: 10%;
  /* padding-top: 6px; */

}
.customNavLink:hover{
  animation-name: flash;
  animation-iteration-count: infinite;
  text-decoration: none;
  color: white;
  animation-duration: 2.5s;
  animation-direction: alternate;
}
.active {
  border-bottom: white 1px solid;
}
.customNavLinks {
  display: flex;
  flex-flow: row-reverse wrap;
  width: 100%;
  justify-content: flex-start;
  list-style-type: none;
  /* overflow: hidden; */
  float: right;
  /* width: 50%; */
  margin-bottom: 0%;
  align-items: center;
}

.nav-links{
  font-family: 'Raleway', sans-serif;
}

/* end Nav */

.titleStyling{
  font-weight: bold;
}

.buttonClass {
  color: #fff;
  background-color: black;
  border-color: #343a40;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  /* border: 1px solid transparent; */
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  animation-name: buttonAnimation;
  margin: 5px;
  opacity: .6;
  transition: .2s
}
.buttonClass:hover {
  text-decoration: none;
  color: white;
  /* opacity: .3; */
  box-shadow: 0px 0px 4px 4px rgb(148, 148, 148);
}
.projectRow {
  padding-top: 25px;
}

.cardCSS {
  padding-bottom: 25px;
}

.projectCard {
  width: 100%;
  height: 615px;
  background-color: #343A40;
  border-radius: 10px;
  margin: 0% 0% 0% 3%;
  padding: 10px;    
  color: #8f8f8f;
  font-weight: bold;
  border: solid 6px #808080;
  box-shadow:5px 3px 5px 1px rgb(77, 77, 77);
}

.headerSpacing{
  margin-bottom: 15px;
}

.stStyling {
  font-size: 20px !important;
  font-weight: bold;
  margin-bottom: 32px;
}
.skillsCard {
  background-color: white;
  height: 415px;
  border-radius: 10px;
  margin: 0% 0% 0% 3%;
  padding: 10px;    
  /* font-weight: bold; */
  border: solid 6px #808080;
  box-shadow:5px 3px 5px 1px rgb(77, 77, 77);
}

.col-md-3{
  padding: 1% 1% 1% 2%;
}

.row{
  width: 100%;
}

.btn{
  margin: 5px;
}

/* Contact Page */
.contactCard {
  position: relative;
  border-radius: 10px;
  transition-duration: .5s;
  height: 200px;
  border: solid 6px #808080;
  transition-timing-function: ease;
}
.contactCard:hover, .resumeItem:hover {
  box-shadow:5px 3px 5px 1px rgb(77, 77, 77);
  right: +5px;
  bottom: +5px;
}

.contactImage {
  margin: auto;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: solid 1px black;
}

.contactBox {
  width: 50px;
  height: 50px;
  border: solid 1px black;
}

.buttonAnimation, .navAnimation, .textAnimate, .cardAnimate, .skillsAnimate {
  animation-fill-mode: forwards;
  animation-duration: 180s;
  animation-delay: 4s;
}

.resumeContainer{
  display: flex;
  flex-direction: column;

}
.resumeItem {
  background-color: white;
  padding-bottom: 1%;
  padding-top: 1%;
  margin-top: 2%;
  position: relative;
  border-radius: 10px;
  transition-duration: .5s;
  border: solid 6px #808080;
  transition-timing-function: ease;
}
.resumeFlexBody {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: left;
  margin-left: 5%;
  margin-right: 5%;
}


/* Animation */

.skillsAnimate {
  animation-name: skillsAnimate;
}
.buttonAnimation {
  animation-name: darkAnimate;
}
.navAnimation{
  animation-name: nav-animate;  
}
.textAnimate {
  animation-name: colorfulText;
}
.cardAnimate {
  animation-name: cardAnimate;
}

.card-title{
  font-family: 'Raleway', sans-serif;
  font-size: 200%;
  font-weight: bold;
}
.card-text{
  font-family: 'Montserrat', sans-serif;
  font-size: 100%;
}

.wrapper{
  bottom: 0;
  background-color: #DDDFDF
}

@keyframes flash{
  from {
    opacity: 1
  } 
  to {
    opacity: .5
  }
}
@keyframes skillsAnimate{
  0% {
    border: solid 6px #808080;
  }
  100% {
    border: solid 6px #2377A4;
  }
}
@keyframes darkAnimate{
  0% {
    background-color: black;
  }
  100% {
    background-color: #2377A4;
  }
}
@keyframes nav-animate{
  0% {
    background-color: #343a40;
    /* border-bottom: solid 4px #808080; */
  }
  100% {
    background-color: #2377A4;
    /* border-bottom: solid 4px #185170; */
  }
}
@keyframes cardAnimate{
  0% {
    background-color: #343a40;
    color: #8f8f8f;
    border: solid 6px #808080;
  }
  50%{
    color: white;
  }
  100% {
    border: solid 6px #185170;
    color: white;
    background-color: #50a3c6;
  }
}

@keyframes colorfulText {
  0% {
    color: black;
  }

  100%{
    color: #187bcd;
  }
}

/* Media Queries */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .projectRow{
    padding-top: 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}